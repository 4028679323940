import { Component, OnInit, AfterViewInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { MatTableColumnDefinitionModel } from 'src/core/models/mat-table/mat-table-column-definition.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { LocalizationService } from '@abp/ng.core';
import { CrudService } from 'src/core/services/crud/crud.service';
import { SorterItemDto } from 'src/core/models/request/sorter-item.dto';
import { ReceiverDto } from 'src/core/models/receiver/receiver.dto';
import { ReceiverAddModalComponent } from '../add-modal/receiver-add-modal.component';

@Component({
    selector: 'cr-receiver-list',
    templateUrl: './receivers-list.component.html',
    styleUrls: ['./receivers-list.component.scss'],
})

export class ReceiversListComponent implements OnInit, AfterViewInit {
    data: ReceiverDto[] = [];
    dataSource = new MatTableDataSource<ReceiverDto>();
    displayedColumns: string[] = [];
    isLoading: boolean = false;
    audioFormats: any[] = JSON.parse(localStorage.audioFormats);
    sorters: SorterItemDto[] = [];
    totalCount = 0;
    currentPage = 0;
    pageSize = 10;
    sortField = 'Id';
    sortDirection = 'asc';

    @Output()
    showDetails: EventEmitter<{
        data: ReceiverDto
    }> = new EventEmitter();

    @Output()
    deleteRow: EventEmitter<{
        id: number
    }> = new EventEmitter();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    @ViewChild('addModal', { static: true, read: ReceiverAddModalComponent })
    addModal: ReceiverAddModalComponent;

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    gridColumns: MatTableColumnDefinitionModel[] = [
        {
            columnName: 'name',
            header: this.L.instant("::Name"),
            binding: 'name',
            valueParser: undefined,
            pipes: undefined,
            width: '150px',
            tooltip: undefined,
            sorterFieldName: "name"
        },
        {
            columnName: 'email',
            header: this.L.instant("::Email"),
            binding: 'email',
            valueParser: undefined,
            pipes: undefined,
            width: '200px',
            tooltip: undefined,
            sorterFieldName: "email"
        },
        {
            columnName: 'phone',
            header: this.L.instant("::Phone"),
            binding: 'phone',
            valueParser: undefined,
            pipes: undefined,
            width: '200px',
            tooltip: undefined,
            sorterFieldName: "phone"
        },
        {
            columnName: 'isActive',
            header: this.L.instant("::IsActive"),
            binding: 'isActive',
            valueParser: undefined,
            pipes: undefined,
            width: '100px',
            tooltip: undefined,
            sorterFieldName: "IsActive"
        },
        {
            columnName: 'actions',
            header: this.L.instant("::Actions"),
            binding: '',
            valueParser: undefined,
            pipes: undefined,
            width: '50px',
            tooltip: undefined,
        },
    ];

    constructor(private crudService: CrudService, public dialog: MatDialog, private L: LocalizationService) {
        for (const gridColumn of this.gridColumns) {
            this.displayedColumns.push(gridColumn.columnName);
        }
    }

    public createColumnFilterString() {
        if (this.sortField) {
            let existingItem = null;
            let existingItemIndex = -1;

            this.sorters.forEach((item, index) => {
                if (item.field.toLowerCase() == this.sortField.toLowerCase()) {
                    existingItem = item;
                    existingItemIndex = index;
                    return;
                }
            });

            if (this.sortDirection) {
                if (existingItem) {
                    existingItem.direction = this.sortDirection;
                } else {
                    this.sorters.push({
                        field: this.sortField,
                        direction: this.sortDirection.toUpperCase(),
                    });
                }
            }
            else {
                this.sorters.splice(existingItemIndex, 1);
            }
        }

        if(this.sorters.length > 0){
            let columnFilters = this.sorters.map(item => `${item.field} ${item.direction.toUpperCase()}`).reverse();
            return columnFilters.join(",");
        }
        return "";
    }

    public getAllReceivers() {
        this.isLoading = true;
        const columnFilterString = this.createColumnFilterString();
        this.crudService
            .abpGet<ReceiverDto>(
                ReceiverDto,
                {
                    filters: null,
                    sorters: this.sorters,
                    maxResultCount: this.pageSize,
                    skipCount: this.currentPage * this.pageSize,
                }, null, columnFilterString, null)
            .subscribe(result => {
                this.isLoading = false;
                this.data = result.items;
                this.totalCount = result.totalCount;
                this.dataSource.connect().next(this.data);
            });
    }

    onPageChange(event?: PageEvent) {
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex;
        this.getAllReceivers()
    }

    sortData(eventArgs: Sort) {
        this.sortDirection = eventArgs.direction;
        this.sortField = eventArgs.active;
        this.refresh();
    }

    refresh(): void {
        this.currentPage = 0;
        this.getAllReceivers();
    }

    public changeStatus(element) {
        this.isLoading = true;
        element.isActive = !element.isActive;

        this.crudService
            .update<ReceiverDto>(ReceiverDto, element)
            .subscribe(res => {
                this.getAllReceivers();
            })
    }

    openAddModal(){
        this.addModal.isModalOpen = true;
    }

    openEditModal(rowData) {
        this.showDetails.emit({ data: rowData });
    }

    openDeleteModal(rowData) {
        this.deleteRow.emit(rowData.id);
    }

    ngOnInit() {
        this.getAllReceivers();
    }
}