import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const ALARM_ROUTE_PROVIDER = [
    { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];


function configureRoutes(routes: RoutesService) {
    return () => {
        routes.add([
            {
                name: '::Menu:Alarms',
                iconClass: 'far fa-bell',
                order: 4,
                path: '',
              },
            {
                path: '/receivers',
                iconClass: 'far fa-user',
                name: '::Menu:Receivers',
                layout: eLayoutType.application,
                parentName: '::Menu:Alarms',
                requiredPolicy: 'AlarmPermissions.Receiver.View',
            },
            {
                path: '/alarm-receivers',
                iconClass: 'far fa-address-book',
                name: '::Menu:AlarmReceivers',
                layout: eLayoutType.application,
                parentName: '::Menu:Alarms',
                requiredPolicy: 'AlarmPermissions.AlarmReceiver.View',
            }
        ]);
    };
}