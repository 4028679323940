<abp-modal [(visible)]="isModalOpen" [(busy)]="inProgress" [options]="{ size: 'xl' }">
  <ng-template #abpHeader>
      <h3>{{ selectedAlarm.name }}</h3>
  </ng-template>
 
  <receiver-selectable-add-modal #addModal (onInsertSaved)="getReceiversByAlarmId()"></receiver-selectable-add-modal>
  <ng-template #abpBody>
    <div class="add-btn-div">
      <button mat-flat-button (click)="openAddModal()" color="accent">{{ '::Add' | abpLocalization }}</button>
    </div>
    
    <table mat-table matSort [dataSource]="dataSource" class="fixed-table">
      <ng-container *ngFor=" let gridColumn of gridColumns">

        <ng-container *ngIf="gridColumn.columnName == 'actions'" matColumnDef="{{ gridColumn.columnName }}">
          <th mat-header-cell *matHeaderCellDef [style.width]="gridColumn.width" [style.minWidth]="gridColumn.width"
            [style.maxWidth]="gridColumn.width" class="receiver-header">
            {{ gridColumn.header }}
          </th>
          <td class="action-btns" mat-cell *matCellDef="let row">
            <button  title="{{ '::Delete' | abpLocalization }}" mat-icon-button color="primary" (click)="deleteRecord(row)">
              <mat-icon fontSet="fa" fontIcon="fa-trash"></mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container *ngIf="gridColumn.columnName !== 'isActive' && gridColumn.columnName !== 'actions'"
          matColumnDef="{{ gridColumn.columnName }}">
          <th [mat-sort-header]="gridColumn.sorterFieldName" mat-header-cell *matHeaderCellDef
            [style.width]="gridColumn.width" [style.minWidth]="gridColumn.width" [style.maxWidth]="gridColumn.width"
            class="receiver-header">
            {{ gridColumn.header }}
          </th>
          <td mat-cell *matCellDef="let row" container="body" [innerHtml]="row | objectparser : gridColumn"></td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div *ngIf="isLoading" style="display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.336);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;">
      <mat-progress-spinner color="primary" mode="indeterminate">
      </mat-progress-spinner>
    </div>
  </ng-template>
</abp-modal>