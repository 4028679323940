import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';

@Component({
  selector: 'cr-recorder-tenant-settings',
  templateUrl: './recorder-tenant-settings.component.html',
  styleUrls: ['./recorder-tenant-settings.component.scss'],
})
export class RecorderTenantSettingsComponent implements OnInit {
  form: FormGroup;

  private readonly prefix = 'Recorder';
  private readonly continueRecordingOnHold = `${this.prefix}.ContinueRecordingOnHold`;
  private readonly addSilenceForHold = `${this.prefix}.AddSilenceForHold`;
  private readonly caUsername = `${this.prefix}.CAUsername`;
  private readonly caKeyword = `${this.prefix}.CAKeyword`;
  private readonly caSourceName = `${this.prefix}.CASourceName`;
  private readonly deleteMigratedAudioFile = `${this.prefix}.DeleteMigratedAudioFile`;


  constructor(
    private localizationService: LocalizationService,
    private fb: FormBuilder,
    private config: ConfigStateService,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService) {

    this.form = this.fb.group({
      continueRecordingOnHold: this.fb.group({
        name: this.continueRecordingOnHold,
        value: [
          this.config.getSetting(this.continueRecordingOnHold) == "true",
        ],
      }),
      addSilenceForHold: this.fb.group({
        name: this.addSilenceForHold,
        value: [
          this.config.getSetting(this.addSilenceForHold) == "true",
        ],
      }),
      caUsername: this.fb.group({
        name: this.caUsername,
        value: [
          this.config.getSetting(this.caUsername),
        ],
      }),
      caKeyword: this.fb.group({
        name: this.caKeyword,
        value: [
          this.config.getSetting(this.caKeyword),
        ],
      }),
      caSourceName: this.fb.group({
        name: this.caSourceName,
        value: [
          this.config.getSetting(this.caSourceName),
        ],
      }),
      deleteMigratedAudioFile: this.fb.group({
        name: this.deleteMigratedAudioFile,
        value: [
          this.config.getSetting(this.deleteMigratedAudioFile) == "true",
        ],
      }),
    })
  }


  onSubmitForm(eventArgs): void {
    if (!this.form.valid) {
      return;
    }

    const settings: ConfigurationSettingsDto[] = [];

    settings.push({
      settingName: this.form.get('continueRecordingOnHold').get('name').value,
      settingValue: this.form.get('continueRecordingOnHold').get('value').value.toString(),
    });

    settings.push({
      settingName: this.form.get('addSilenceForHold').get('name').value,
      settingValue: this.form.get('addSilenceForHold').get('value').value.toString(),
    });

    settings.push({
      settingName: this.form.get('caUsername').get('name').value,
      settingValue: this.form.get('caUsername').get('value').value.toString(),
    });

    settings.push({
      settingName: this.form.get('caKeyword').get('name').value,
      settingValue: this.form.get('caKeyword').get('value').value.toString(),
    });

    settings.push({
      settingName: this.form.get('caSourceName').get('name').value,
      settingValue: this.form.get('caSourceName').get('value').value.toString(),
    });

    settings.push({
      settingName: this.form.get('deleteMigratedAudioFile').get('name').value,
      settingValue: this.form.get('deleteMigratedAudioFile').get('value').value.toString(),
    });

    this.settingService.saveSetting(settings).subscribe(res => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
      );
    });
  }

  ngOnInit(): void { }
}
