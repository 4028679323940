<form [formGroup]="form" (ngSubmit)="onSubmitForm($event)">
  <div class="form-group pt-2" formGroupName="tenantName">
    <label for="tenantName">{{ 'Settings::Five9IntegrationTenantName' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="tenantName"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="productClientId">
    <label for="productClientId">{{ 'Settings::Five9IntegrationProductClientId' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="productClientId"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="productClientSecret">
    <label for="productClientSecret">{{ 'Settings::Five9IntegrationProductClientSecret' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="productClientSecret"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="clientId">
    <label for="clientId">{{ 'Settings::Five9IntegrationClientId' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="clientId"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="clientSecret">
    <label for="clientSecret">{{ 'Settings::Five9IntegrationClientSecret' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="clientSecret"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="groupId">
    <label for="groupId">{{ 'Settings::Five9IntegrationGroupId' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="groupId"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="supervisorId">
    <label for="supervisorId">{{ 'Settings::Five9IntegrationSupervisorId' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="supervisorId"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="campaignId">
    <label for="campaignId">{{ 'Settings::Five9IntegrationCampaignId' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="campaignId"
      formControlName="value" />
  </div>
  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3" [disabled]="!form.valid">
    <i class="me-1 fas fa-save"></i>{{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
