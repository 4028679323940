import { Environment } from '@abp/ng.core';

const baseUrl = window.location.origin;

const oAuthConfig = {
  issuer: '/cr-identity',
  skipIssuerCheck: true,
  strictDiscoveryDocumentValidation: false,
  showDebugInformation: true,
  redirectUri: baseUrl,
  clientId: 'CR_App',
  responseType: 'code',
  scope: 'offline_access CR',
  requireHttps: false,
};

export const environment = {
  production: true,
  hmr: false,
  application: {
    baseUrl,
    name: 'RTG Recorder | Sestek',
    logoUrl: '/assets/images/logo/logo-dark.png'
  },
  oAuthConfig,
  apis: {
    default: {
      url: '',
      rootNamespace: 'Sestek.CR',
    },
    AbpAccountPublic: {
      url: oAuthConfig.issuer,
      rootNamespace: 'AbpAccountPublic',
    },
  },
  stateNameSpace: 'CR',
} as Environment;
