import { Component, OnInit, AfterViewInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatTableColumnDefinitionModel } from 'src/core/models/mat-table/mat-table-column-definition.model';
import { MatDialog } from '@angular/material/dialog';
import { LocalizationService } from '@abp/ng.core';
import { CrudService } from 'src/core/services/crud/crud.service';
import { MatTableDataSource } from '@angular/material/table';
import { SorterItemDto } from 'src/core/models/request/sorter-item.dto';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { AlarmDto } from 'src/core/models/alarm/alarm.dto';
import { AlarmReceiversListModalComponent } from '../alarm-receiver-list-modal/alarm-receivers-list-modal.component';

@Component({
    selector: 'alarm-list',
    templateUrl: './alarm-list.component.html',
    styleUrls: ['./alarm-list.component.scss'],
})


export class AlarmListComponent implements OnInit, AfterViewInit {
    @Output()
    showDetails: EventEmitter<{
        data: AlarmDto
    }> = new EventEmitter();

    @ViewChild('alarmReceiverListModal', { static: true, read: AlarmReceiversListModalComponent })
    alarmReceiverListModal: AlarmReceiversListModalComponent;

    data: AlarmDto[] = [];
    dataSource = new MatTableDataSource<AlarmDto>();
    displayedColumns: string[] = [];
    isLoading: boolean = false;
    audioFormats: any[] = JSON.parse(localStorage.audioFormats);
    sorters: SorterItemDto[] = [];
    totalCount = 0;
    currentPage = 0;
    pageSize = 10;
    sortField = 'Id';
    sortDirection = 'asc';
    isExpand = false;
    
    ngAfterViewInit() {
    }
    
    gridColumns: MatTableColumnDefinitionModel[] = [
        {
            columnName: 'name',
            header: this.L.instant("::Name"),
            binding: 'name',
            valueParser: undefined,
            pipes: undefined,
            width: '200px',
            tooltip: undefined,
            sorterFieldName: "name"
        },
        {
            columnName: 'isActive',
            header: this.L.instant("::IsActive"),
            binding: 'isActive',
            valueParser: undefined,
            pipes: undefined,
            width: '100px',
            tooltip: undefined,
            sorterFieldName: "IsActive"
        },
        {
            columnName: 'actions',
            header: this.L.instant("::Actions"),
            binding: '',
            valueParser: undefined,
            pipes: undefined,
            width: '50px',
            tooltip: undefined,
        },
    ];

    constructor(private crudService: CrudService, public dialog: MatDialog, private L: LocalizationService) {
        for (const gridColumn of this.gridColumns) {
            this.displayedColumns.push(gridColumn.columnName);
        }
    }

    onPageChange(event?: PageEvent) {
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex;
        this.getAllAlarms()
    }

    sortData(eventArgs: Sort) {
        this.sortDirection = eventArgs.direction;
        this.sortField = eventArgs.active;
        //this.refresh();
    }

    public changeStatus(element) {
        this.isLoading = true;
        element.isActive = !element.isActive;

        this.crudService
            .update<AlarmDto>(AlarmDto, element)
            .subscribe(res => {
                this.getAllAlarms();
            })
    }

    public createColumnFilterString() {
        if (this.sortField) {
            let existingItem = null;
            let existingItemIndex = -1;

            this.sorters.forEach((item, index) => {
                if (item.field.toLowerCase() == this.sortField.toLowerCase()) {
                    existingItem = item;
                    existingItemIndex = index;
                    return;
                }
            });

            if (this.sortDirection) {
                if (existingItem) {
                    existingItem.direction = this.sortDirection;
                } else {
                    this.sorters.push({
                        field: this.sortField,
                        direction: this.sortDirection.toUpperCase(),
                    });
                }
            }
            else {
                this.sorters.splice(existingItemIndex, 1);
            }
        }

        if(this.sorters.length > 0){
            let columnFilters = this.sorters.map(item => `${item.field} ${item.direction.toUpperCase()}`).reverse();
            return columnFilters.join(",");
        }
        return "";
    }

    public getAllAlarms() {
        this.isLoading = true;
        const columnFilterString = this.createColumnFilterString();
        this.crudService
            .abpGet<AlarmDto>(
                AlarmDto,
                {
                    filters: null,
                    sorters: this.sorters,
                    maxResultCount: this.pageSize,
                    skipCount: this.currentPage * this.pageSize,
                }, null, columnFilterString, null)
            .subscribe(result => {
                this.isLoading = false;
                this.data = result.items;
                this.totalCount = result.totalCount;
                this.dataSource.connect().next(this.data);
            });
    }

    openAlarmReceiversModal(rowData){
        this.alarmReceiverListModal.selectedAlarm = rowData;
        this.alarmReceiverListModal.getReceiversByAlarmId();
        this.alarmReceiverListModal.isModalOpen = true;
    }

    ngOnInit() {
        this.getAllAlarms();
    }
}