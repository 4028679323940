import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const RECORDER_ROUTE_PROVIDER = [
    { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];


function configureRoutes(routes: RoutesService) {
    return () => {
        routes.add([
            {
                name: '::Menu:Recorder',
                iconClass: 'fa fa-microphone',
                order: 3,
                path: '',
              },
            {
                path: '/voice-file',
                iconClass: 'far fa-file-audio',
                name: '::Menu:VoiceFileInformation',
                layout: eLayoutType.application,
                parentName: '::Menu:Recorder',
                requiredPolicy: 'RecorderSettingsPermissions.VoiceFileInformation.View',
            },
            // {
            //     path: '/settings',
            //     iconClass: 'fa fa-cog',
            //     name: '::Menu:RecorderSettings',
            //     layout: eLayoutType.application,
            //     parentName: '::Menu:Recorder',
            //     requiredPolicy: 'RecorderSettingsPermissions.RecorderSetting.View',
            // },
        ]);
    };
}