import { Api } from "src/core/decorators/dto/api.decorator";
import { DtoHasApi } from "../shared/dto-has-api.model";
import { AudioFormatDto } from "../audio-format/audio-format-dto";
import { StoragePropertyDto } from "../storage-property/storage-property.dto";

@Api('api/app/voice-file-informations')
export class VoiceFileInformationDto extends DtoHasApi {
    id: number;
    storageTypeId: number | null;
    audioFormatId: number | null;
    relativePath: string;
    fileExtension: string;
    isActive: boolean | null;
    tenantId: string;
    audioformatname: string;
    audioFormat: AudioFormatDto | null;
    storageProperties: StoragePropertyDto[] | null;
}