import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomFieldListComponent } from './list/custom-field-list.component';
import { CustomFieldEditModalComponent } from './edit-modal/custom-field-edit-modal.component';
import { CustomFieldDeleteModalComponent } from './delete-modal/custom-field-delete-modal.component';

@Component({
  selector: 'custom-field',
  templateUrl: './custom-field.component.html',
  styleUrls: ['./custom-field.component.scss'],
})

export class CustomFieldComponent implements OnInit {
  @ViewChild('editModal', { static: true, read: CustomFieldEditModalComponent })
  editModal: CustomFieldEditModalComponent;

  @ViewChild('deleteModal', { static: true, read: CustomFieldDeleteModalComponent })
  deleteModal: CustomFieldDeleteModalComponent;

  @ViewChild('list', { static: true, read: CustomFieldListComponent })
  list: CustomFieldListComponent;

  constructor() { }

  ngOnInit(): void {}

  getCustomFields(event){
    this.list.getCustomFields();
  }

  openEditModal(data: any) {
    this.editModal.load(data.data);
    this.editModal.isModalOpen = true;
  }

  openDeleteModal(id: number){
    this.deleteModal.selectedCfId = id;
    this.deleteModal.isModalOpen = true;
  }
}