<abp-modal [(visible)]="isModalOpen" [(busy)]="inProgress">
  <ng-template #abpHeader>
    <h3>{{ '::CustomFieldModalHeader' | abpLocalization }}</h3>
  </ng-template>

  <ng-template #abpBody>
    <form id="cf-form" [formGroup]="form">
      <div class="form-group">
        <label for="fieldKey">{{ '::FieldKey' | abpLocalization }}</label>
        <input type="text" id="fieldKey" class="form-control" formControlName="fieldKey" />
      </div>

      <div class="form-group">
        <label for="pbxKey">{{ '::PbxKey' | abpLocalization }}</label>
        <input type="text" id="pbxKey" class="form-control" formControlName="pbxKey" />
      </div>
    </form>
  </ng-template>

  <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ '::Cancel' | abpLocalization }}
    </button>

    <button form="cf-form" class="btn btn-primary" [disabled]="form.invalid || form.pristine" (click)="save()">
      <i class="fa fa-check mr-1"></i>
      {{ '::Save' | abpLocalization }}
    </button>
  </ng-template>
</abp-modal>