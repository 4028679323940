import { Component, OnInit, ViewChild } from '@angular/core';
import { VoiceFileInformationEditModalComponent } from './edit-modal/voice-file-information-edit-modal.component';
import { AudioFormatService } from 'src/core/services/audio-formats/audio-format-service';
import { VoiceFileInformationDeleteModalComponent } from './delete-modal/voice-file-information-delete-modal.component';
import { VoiceFileInformationListComponent } from './list/voice-file-information-list.component';

@Component({
  selector: 'cr-voice-file-information',
  templateUrl: './voice-file-information.component.html',
  styleUrls: ['./voice-file-information.component.scss'],
})

export class VoiceFileInformationComponent implements OnInit {
  @ViewChild('editModal', { static: true, read: VoiceFileInformationEditModalComponent })
  editModal: VoiceFileInformationEditModalComponent;

  @ViewChild('deleteModal', { static: true, read: VoiceFileInformationDeleteModalComponent })
  deleteModal: VoiceFileInformationDeleteModalComponent;

  @ViewChild('list', { static: true, read: VoiceFileInformationListComponent })
  list: VoiceFileInformationListComponent;

  constructor(private service: AudioFormatService) { }

  ngOnInit(): void {}

  getVoiceFileInformationList(event){
    this.list.getVoiceFileInformationList();
  }

  openEditModal(data: any) {
    this.editModal.load(data.data);
    this.editModal.isModalOpen = true;
  }

  openDeleteModal(id: number){
    this.deleteModal.selectedVfiId = id;
    this.deleteModal.isModalOpen = true;
  }
}