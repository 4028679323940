<abp-modal [(visible)]="isModalOpen" [(busy)]="inProgress">
  <ng-template #abpHeader>
    <h3>{{ '::VoiceFileInformationModalHeader' | abpLocalization }}</h3>
  </ng-template>

  <ng-template #abpBody>
    <form id="vfi-form" [formGroup]="form">
      <div class="form-group">

        <label for="storageTypeId">{{ '::StorageType' | abpLocalization }}</label>
        <div>
          <select (change)="storageTypeHasChanged($event.target.value)" class="select-box" matNativeControl
            formControlName="storageTypeId" id="storageTypeId">
            <option *ngFor="let option of storageTypes" [value]="option.id">{{option.name}}</option>
          </select>
        </div>
      </div>

      <div *ngIf="isStorageTypeAws">
        <div class="form-group">
          <label for="awsAccessKeyId">{{ '::AwsAccessKeyId' | abpLocalization }}</label>
          <input type="text" id="awsAccessKeyId" class="form-control" formControlName="awsAccessKeyId" />
        </div>
        <div class="form-group">
          <label for="awsSecretAccessKey">{{ '::AwsSecretAccessKey' | abpLocalization }}</label>
          <input type="text" id="awsSecretAccessKey" class="form-control" formControlName="awsSecretAccessKey" />
        </div>
        <div class="form-group">
          <label for="awsRegion">{{ '::AwsRegion' | abpLocalization }}</label>
          <input type="text" id="awsRegion" class="form-control" formControlName="awsRegion" />
        </div>
        <div class="form-group">
          <label for="awsBucketName">{{ '::AwsBucketName' | abpLocalization }}</label>
          <input type="text" id="awsBucketName" class="form-control" formControlName="awsBucketName" />
        </div>
        <div class="form-group">
          <label for="awsServiceUrl">{{ '::AwsServiceUrl' | abpLocalization }}</label>
          <input type="text" id="awsServiceUrl" class="form-control" formControlName="awsServiceUrl" />
        </div>
        <div class="form-group">
          <label for="shouldWebProxySetNull">{{ '::ShouldWebProxySetNull' | abpLocalization }}</label>
          <select class="select-box" matNativeControl formControlName="shouldWebProxySetNull"
            id="shouldWebProxySetNull">
            <option value="true">{{ '::Yes' | abpLocalization }}</option>
            <option value="false">{{ '::No' | abpLocalization }}</option>
          </select>
        </div>
      </div>

      <!-- <div class="form-group">
        <label for="relativePath">{{ '::RelativePath' | abpLocalization }}</label>
        <input type="text" id="relativePath" class="form-control" formControlName="relativePath" />
      </div> -->

      <div class="form-group">
        <label for="audioFormatId">{{ '::AudioFormat' | abpLocalization }}</label>
        <div>
          <select matNativeControl class="select-box" formControlName="audioFormatId" id="audioFormatId">
            <option *ngFor="let option of audioFormats" [value]="option.id">{{option.name}}</option>
          </select>
        </div>
      </div>

      <div class="form-group">
        <label for="fileExtension">{{ '::FileExtension' | abpLocalization }}</label>
        <input type="text" placeholder=".mp3" id="fileExtension" class="form-control" formControlName="fileExtension" />
      </div>
    </form>
  </ng-template>

  <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ '::Cancel' | abpLocalization }}
    </button>

    <button form="vfi-form" class="btn btn-primary" [disabled]="form.invalid || form.pristine" (click)="save()">
      <i class="fa fa-check mr-1"></i>
      {{ '::Save' | abpLocalization }}
    </button>
  </ng-template>
</abp-modal>