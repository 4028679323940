import { Component, OnInit, AfterViewInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { MatTableColumnDefinitionModel } from 'src/core/models/mat-table/mat-table-column-definition.model';
import { VoiceFileInformationDto } from 'src/core/models/voice-file-information/voice-file-information.dto';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { LocalizationService } from '@abp/ng.core';
import { CrudService } from 'src/core/services/crud/crud.service';
import { SorterItemDto } from 'src/core/models/request/sorter-item.dto';
import { VoiceFileInformationAddModalComponent } from '../add-modal/voice-file-information-add-modal.component';
import { StorageType } from 'src/cr-shared/voice-file-information/storage-type-enum';

@Component({
    selector: 'cr-voice-file-information-list',
    templateUrl: './voice-file-information-list.component.html',
    styleUrls: ['./voice-file-information-list.component.scss'],
})

export class VoiceFileInformationListComponent implements OnInit, AfterViewInit {
    data: VoiceFileInformationDto[] = [];
    dataSource = new MatTableDataSource<VoiceFileInformationDto>();
    displayedColumns: string[] = [];
    isLoading: boolean = false;
    audioFormats: any[] = JSON.parse(localStorage.audioFormats);
    sorters: SorterItemDto[] = [];
    totalCount = 0;
    currentPage = 0;
    pageSize = 10;
    sortField = 'Id';
    sortDirection = 'asc';

    @Output()
    showDetails: EventEmitter<{
        data: VoiceFileInformationDto
    }> = new EventEmitter();

    @Output()
    deleteRow: EventEmitter<{
        id: number
    }> = new EventEmitter();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    @ViewChild('addModal', { static: true, read: VoiceFileInformationAddModalComponent })
    addModal: VoiceFileInformationAddModalComponent;

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    gridColumns: MatTableColumnDefinitionModel[] = [
        {
            columnName: 'audioFormatId',
            header: this.L.instant("::AudioFormat"),
            binding: 'audioFormatId',
            valueParser: val => {
                let audioFormatName = this.audioFormats.find(item => item.id == val).name;
                return audioFormatName;
            },
            pipes: undefined,
            width: '150px',
            tooltip: undefined,
            sorterFieldName: "AudioFormatId"
        },
        {
            columnName: 'storageTypeId',
            header: this.L.instant("::StorageType"),
            binding: 'storageTypeId',
            valueParser: undefined,
            pipes: undefined,
            width: '150px',
            tooltip: undefined,
            sorterFieldName: "StorageTypeId"
        },
        // {
        //     columnName: 'relativePath',
        //     header: this.L.instant("::RelativePath"),
        //     binding: 'relativePath',
        //     valueParser: undefined,
        //     pipes: undefined,
        //     width: '200px',
        //     tooltip: undefined,
        //     sorterFieldName: "RelativePath"
        // },
        {
            columnName: 'fileExtension',
            header: this.L.instant("::FileExtension"),
            binding: 'fileExtension',
            valueParser: undefined,
            pipes: undefined,
            width: '150px',
            tooltip: undefined,
            sorterFieldName: "FileExtension"
        },
        {
            columnName: 'isActive',
            header: this.L.instant("::IsActive"),
            binding: 'isActive',
            valueParser: undefined,
            pipes: undefined,
            width: '100px',
            tooltip: undefined,
            sorterFieldName: "IsActive"
        },
        {
            columnName: 'actions',
            header: this.L.instant("::Actions"),
            binding: '',
            valueParser: undefined,
            pipes: undefined,
            width: '50px',
            tooltip: undefined,
        },
    ];

    constructor(private crudService: CrudService, public dialog: MatDialog, private L: LocalizationService) {
        for (const gridColumn of this.gridColumns) {
            this.displayedColumns.push(gridColumn.columnName);
        }
    }

    public createColumnFilterString() {
        if (this.sortField) {
            let existingItem = null;
            let existingItemIndex = -1;

            this.sorters.forEach((item, index) => {
                if (item.field.toLowerCase() == this.sortField.toLowerCase()) {
                    existingItem = item;
                    existingItemIndex = index;
                    return;
                }
            });

            if (this.sortDirection) {
                if (existingItem) {
                    existingItem.direction = this.sortDirection;
                } else {
                    this.sorters.push({
                        field: this.sortField,
                        direction: this.sortDirection.toUpperCase(),
                    });
                }
            }
            else {
                this.sorters.splice(existingItemIndex, 1);
            }
        }

        if(this.sorters.length > 0){
            let columnFilters = this.sorters.map(item => `${item.field} ${item.direction.toUpperCase()}`).reverse();
            return columnFilters.join(",");
        }
        return "";
    }

    public getVoiceFileInformationList() {
        this.isLoading = true;
        const columnFilterString = this.createColumnFilterString();
        this.crudService
            .abpGet<VoiceFileInformationDto>(
                VoiceFileInformationDto,
                {
                    filters: null,
                    sorters: this.sorters,
                    maxResultCount: this.pageSize,
                    skipCount: this.currentPage * this.pageSize,
                }, null, columnFilterString, "with-storage-property")
            .subscribe(result => {
                this.isLoading = false;
                this.prepareResultData(result.items);
                this.data = result.items;
                this.totalCount = result.totalCount;
                this.dataSource.connect().next(this.data);
            });
    }

    prepareResultData(data){
        data.forEach(row => {
            if(row.storageTypeId == StorageType.AWS){
                row.storageProperties.forEach(prop => {
                    row[prop.key] = prop.value;
                    row[`${prop.key}Id`] = prop.id
                })
            }
        });
    }

    onPageChange(event?: PageEvent) {
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex;
        this.getVoiceFileInformationList()
    }

    sortData(eventArgs: Sort) {
        this.sortDirection = eventArgs.direction;
        this.sortField = eventArgs.active;
        this.refresh();
    }

    refresh(): void {
        this.currentPage = 0;
        this.getVoiceFileInformationList();
    }

    public changeStatus(element) {
        this.isLoading = true;
        element.isActive = !element.isActive;

        this.crudService
            .update<VoiceFileInformationDto>(VoiceFileInformationDto, element)
            .subscribe(res => {
                this.getVoiceFileInformationList();
            })
    }

    openAddModal(){
        this.addModal.isModalOpen = true;
    }

    openEditModal(rowData) {
        this.showDetails.emit({ data: rowData });
    }

    openDeleteModal(rowData) {
        this.deleteRow.emit(rowData.id);
    }

    ngOnInit() {
        this.getVoiceFileInformationList();

    }
}