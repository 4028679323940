import { Component, EventEmitter, Output } from '@angular/core';
import { VoiceFileInformationDto } from 'src/core/models/voice-file-information/voice-file-information.dto';
import { CrudService } from 'src/core/services/crud/crud.service';

@Component({
    selector: 'voice-file-information-delete-modal',
    templateUrl: './voice-file-information-delete-modal.component.html',
    styleUrl: './voice-file-information-delete-modal.component.scss',
})
export class VoiceFileInformationDeleteModalComponent {
  @Output()
  onDeleted: EventEmitter<{}> = new EventEmitter();

  selectedVfiId: number = 0;
  isModalOpen: boolean = false;
  
  constructor(private service: CrudService) {
  }

  delete(){
    this.service.delete(VoiceFileInformationDto, this.selectedVfiId).subscribe(() => {
      this.onDeleted.emit();
      this.isModalOpen = false;
    });
  }
}