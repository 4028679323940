import { LocalizationService } from '@abp/ng.core';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AudioFormatDto } from 'src/core/models/audio-format/audio-format-dto';
import { VoiceFileInformationDto } from 'src/core/models/voice-file-information/voice-file-information.dto';
import { CrudService } from 'src/core/services/crud/crud.service';
import { StorageType } from 'src/cr-shared/voice-file-information/storage-type-enum';

@Component({
  selector: 'voice-file-information-add-modal',
  templateUrl: './voice-file-information-add-modal.component.html',
  styleUrl: './voice-file-information-add-modal.component.scss',
})
export class VoiceFileInformationAddModalComponent {
  @Output()
  onInsertSaved: EventEmitter<{}> = new EventEmitter();

  form = this.fb.group({
    storageTypeId: [0, [Validators.required]],
    // relativePath: ['', [Validators.required]],
    audioFormatId: [0, [Validators.required]],
    fileExtension: ['', [Validators.required]],
    awsAccessKeyId: ['', [Validators.required]],
    awsSecretAccessKey: ['', [Validators.required]],
    awsRegion: ['', [Validators.required]],
    awsBucketName: ['', [Validators.required]],
    awsServiceUrl: ['', [Validators.required]],
    shouldWebProxySetNull: [false, [Validators.required]],
  });

  newVfi: VoiceFileInformationDto = new VoiceFileInformationDto();
  inProgress: boolean;
  isModalOpen: boolean;
  audioFormats: AudioFormatDto[] = [];
  storageTypes: any[] = [];
  isStorageTypeAws: boolean = false;

  constructor(private fb: FormBuilder, private crudService: CrudService, private L: LocalizationService) {
    this.audioFormats = JSON.parse(localStorage.audioFormats)
    this.storageTypes = JSON.parse(localStorage.storageTypes);
  }
  
  prepareStorageTypes() {
    const storagePropArray = [
      {
        Id: 0,
        Key: "awsAccessKeyId",
        Value: this.form.controls["awsAccessKeyId"].value,
        VoiceFileInformationId: 0
      },
      {
        Id: 0,
        Key: "awsSecretAccessKey",
        Value: this.form.controls["awsSecretAccessKey"].value,
        VoiceFileInformationId: 0
      },
      {
        Id: 0,
        Key: "awsRegion",
        Value: this.form.controls["awsRegion"].value,
        VoiceFileInformationId: 0
      },
      {
        Id: 0,
        Key: "awsBucketName",
        Value: this.form.controls["awsBucketName"].value,
        VoiceFileInformationId: 0
      },
      {
        Id: 0,
        Key: "awsServiceUrl",
        Value: this.form.controls["awsServiceUrl"].value,
        VoiceFileInformationId: 0
      },
      {
        Id: 0,
        Key: "shouldWebProxySetNull",
        Value: this.form.controls["shouldWebProxySetNull"].value.toString(),
        VoiceFileInformationId: 0
      },
    ];

    this.newVfi.storageProperties = storagePropArray;
  }

  save() {
    if (this.form.invalid) {
      return;
    }
    this.inProgress = true;
    this.newVfi.audioFormatId = this.form.controls["audioFormatId"].value;
    this.newVfi.storageTypeId = this.form.controls["storageTypeId"].value;
    this.newVfi.relativePath = "{Year}\\{Month}\\{Day}\\{Hour}\\{Extid}";
    this.newVfi.fileExtension = this.form.controls["fileExtension"].value;

    if (this.newVfi.storageTypeId == StorageType.AWS) {
      this.prepareStorageTypes();
    } else {
      this.newVfi.storageProperties = null;
    }

    this.crudService
      .insertWithoutId<VoiceFileInformationDto>(VoiceFileInformationDto, this.newVfi)
      .subscribe((result) => {
        if (result) {
          this.onInsertSaved.emit();
        }
        this.isModalOpen = false;
        this.inProgress = false;
        this.form.reset();
      });
  }

  load(data: VoiceFileInformationDto | null) {
    if (data == null) {
      data = new VoiceFileInformationDto();
    } else {
      this.newVfi = data;
      this.form.patchValue(data);
    }
  }

  storageTypeHasChanged(storageTypeId) {
    if (storageTypeId == StorageType.AWS) {
      this.isStorageTypeAws = true;
      this.addAWSControlsIntoForm();
    } else {
      this.isStorageTypeAws = false;
      this.removeAWSControlsFromForm();
    }
  }

  addAWSControlsIntoForm() {
    this.form.addControl('awsAccessKeyId', this.fb.control('', [Validators.required]));
    this.form.addControl('awsSecretAccessKey', this.fb.control('', [Validators.required]));
    this.form.addControl('awsRegion', this.fb.control('', [Validators.required]));
    this.form.addControl('awsBucketName', this.fb.control('', [Validators.required]));
    this.form.addControl('awsServiceUrl', this.fb.control('', [Validators.required]));
    this.form.addControl('shouldWebProxySetNull', this.fb.control(false, [Validators.required]));
  }

  removeAWSControlsFromForm(){
    this.form.removeControl('awsAccessKeyId');
    this.form.removeControl('awsSecretAccessKey');
    this.form.removeControl('awsRegion');
    this.form.removeControl('awsBucketName');
    this.form.removeControl('awsServiceUrl');
    this.form.removeControl('shouldWebProxySetNull');
  }
}