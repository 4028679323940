import { FeatureService } from './../core/services/feature/feature.service';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { Component } from '@angular/core';
import { SettingTabsService } from '@abp/ng.setting-management/config';
import { IntegrationSettingsComponent } from './administration/settings/integration-settings/integration-settings.component';
import { ConfigStateService } from '@abp/ng.core';
import { Store, UpdateState } from '@ngxs/store';
import { RecorderTenantSettingsComponent } from './administration/settings/recorder-settings/tenant/recorder-tenant-settings.component';
import { RecorderHostSettingsComponent } from './administration/settings/recorder-settings/host/recorder-host-settings.component';
@Component({
  selector: 'app-root',
  template: `
    <abp-loader-bar></abp-loader-bar>
    <abp-dynamic-layout></abp-dynamic-layout>
    <abp-gdpr-cookie-consent></abp-gdpr-cookie-consent>
  `,
})
export class AppComponent {
  constructor(
    private settingTabs: SettingTabsService,
    private configStateService: ConfigStateService,
    private store: Store,
    private featureService: FeatureService)
    {
      this.configStateService.getDeep$('currentUser.id').subscribe(user => {
        if (user != null) {
          const actions = new UpdateState();
          this.store.dispatch(actions);
        }
      });
  
      let tenantId = this.configStateService.getDeep(['currentUser', 'tenantId']);
    }

    ngOnInit(){
      this.settingTabs.add([
        {
          name: 'Settings::Integration',
          requiredPolicy: 'AbpIdentity.SettingManagement',
          component: IntegrationSettingsComponent,
        },
      ])

      this.settingTabs.add([
        {
          name: 'Settings::TenantRecorder',
          requiredPolicy: 'AbpIdentity.SettingManagement',
          component: RecorderTenantSettingsComponent,
        },
      ])

      
      if (this.isHostUser()) {
        this.settingTabs.remove(['Settings::Integration']);
        this.settingTabs.remove(['Settings::TenantRecorder']);

        this.settingTabs.add([
          {
            name: 'Settings::HostRecorder',
            requiredPolicy: 'AbpIdentity.SettingManagement',
            component: RecorderHostSettingsComponent,
          },
        ])
        
      } else if (!this.checkIntegration()) {
        this.settingTabs.remove(['Settings::Integration']);
      }
    }

    isHostUser(): boolean {
      let tenantId = this.configStateService.getDeep(['currentUser', 'tenantId']);
      return tenantId == null;
    }

    private checkIntegration() {
      let amazonIntegration = this.featureService.isEnabled(FeatureConstants.AmazonIntegration);
      let amazonIntegrationOutOfMarketplace = this.featureService.isEnabled(FeatureConstants.AmazonIntegrationOutOfMarketplace);
      let GenesysIntegration = this.featureService.isEnabled(FeatureConstants.GenesysIntegration);
      let GenesysIntegrationOutOfMarketplace = this.featureService.isEnabled(FeatureConstants.GenesysIntegrationOutOfMarketplace);
      let five9Integration = this.featureService.isEnabled(FeatureConstants.Five9Integration);
  
      return amazonIntegration || amazonIntegrationOutOfMarketplace || GenesysIntegration || GenesysIntegrationOutOfMarketplace || five9Integration ;
    }
}
