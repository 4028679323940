import { Component, OnInit, AfterViewInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatTableColumnDefinitionModel } from 'src/core/models/mat-table/mat-table-column-definition.model';
import { MatDialog } from '@angular/material/dialog';
import { LocalizationService } from '@abp/ng.core';
import { CrudService } from 'src/core/services/crud/crud.service';
import { MatTableDataSource } from '@angular/material/table';
import { AlarmReceiverDto } from 'src/core/models/alarm-receiver/alarm-receiver.dto';
import { ReceiverSelectableAddModalComponent } from '../selectable-add-modal/selectable-add-modal.component';

@Component({
    selector: 'alarm-receivers-list-modal',
    templateUrl: './alarm-receivers-list-modal.component.html',
    styleUrls: ['./alarm-receivers-list-modal.component.scss'],
    
})
export class AlarmReceiversListModalComponent implements OnInit, AfterViewInit {
    @Output()
    showDetails: EventEmitter<{
        data: AlarmReceiverDto
    }> = new EventEmitter();

    @ViewChild('addModal', { static: true, read: ReceiverSelectableAddModalComponent })
    addModal: ReceiverSelectableAddModalComponent;

    data: any[] = [];
    dataSource = new MatTableDataSource<any>();
    displayedColumns: string[] = [];
    isLoading: boolean = false;
    isExpand = false;
    inProgress: boolean;
    isModalOpen: boolean;
    selectedAlarm: any;
    
    ngAfterViewInit() {
    }
    
    gridColumns: MatTableColumnDefinitionModel[] = [
        {
            columnName: 'receivername',
            header: this.L.instant("::ReceiverName"),
            binding: 'receivername',
            valueParser: undefined,
            pipes: undefined,
            width: '200px',
            tooltip: undefined,
            sorterFieldName: "receivername"
        },
        {
            columnName: 'email',
            header: this.L.instant("::Email"),
            binding: 'email',
            valueParser: undefined,
            pipes: undefined,
            width: '200px',
            tooltip: undefined,
            sorterFieldName: "email"
        },
        {
            columnName: 'phone',
            header: this.L.instant("::Phone"),
            binding: 'phone',
            valueParser: undefined,
            pipes: undefined,
            width: '200px',
            tooltip: undefined,
            sorterFieldName: "phone"
        },
        {
            columnName: 'actions',
            header: this.L.instant("::Actions"),
            binding: '',
            valueParser: undefined,
            pipes: undefined,
            width: '50px',
            tooltip: undefined,
        }
    ];

    constructor(private crudService: CrudService, public dialog: MatDialog, private L: LocalizationService) {
        for (const gridColumn of this.gridColumns) {
            this.displayedColumns.push(gridColumn.columnName);
        }
    }

    public getReceiversByAlarmId() {
        this.isLoading = true;
        this.data = [];
        this.crudService
            .abpGetByIdAny(
                AlarmReceiverDto,
                `${this.selectedAlarm.id}`, "alarm")
            .subscribe(result => {
                this.isLoading = false;
                result.forEach(item => {
                    let obj = item;
                    obj["name"] = item.receiver.name;
                    obj["email"] = item.receiver.email;
                    obj["phone"] = item.receiver.phone;
                    delete obj.receiver;

                    this.data.push(obj);
                });
                this.dataSource.connect().next(this.data);
            });
    }

    openAddModal(){
        this.addModal.isModalOpen = true;
        this.addModal.alarmReceiversList = this.data;
        this.addModal.selectedAlarm = this.selectedAlarm;
    }

    deleteRecord(rowData){
        this.crudService
        .delete(AlarmReceiverDto, rowData.id)
        .subscribe(() => {
            this.getReceiversByAlarmId();
          });
    }

    ngOnInit() {
    }
}