import { NgModule } from '@angular/core';
import { ObjectParserPipe } from './pipes/object-parser.pipe';
import { CRTimePipe } from './pipes/cr-time.pipe';
import { CRDatePipe } from './pipes/cr-date.pipe';
@NgModule({
  declarations: [
    ObjectParserPipe,
    CRDatePipe,
    CRTimePipe,
  ],
  exports: [
    ObjectParserPipe,
    CRDatePipe,
    CRTimePipe
  ],
  imports: [],
})
export class CoreModule {}
