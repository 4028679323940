import { Component, EventEmitter, Output } from '@angular/core';
import { ReceiverDto } from 'src/core/models/receiver/receiver.dto';
import { CrudService } from 'src/core/services/crud/crud.service';

@Component({
    selector: 'receiver-delete-modal',
    templateUrl: './receiver-delete-modal.component.html',
    styleUrl: './receiver-delete-modal.component.scss',
})
export class ReceiverDeleteModalComponent {
  @Output()
  onDeleted: EventEmitter<{}> = new EventEmitter();

  selectedReceiverId: number = 0;
  isModalOpen: boolean = false;
  
  constructor(private service: CrudService) {
  }

  delete(){ 
    this.service.delete(ReceiverDto, this.selectedReceiverId).subscribe(() => {
      this.onDeleted.emit();
      this.isModalOpen = false;
    });
  }
}