<abp-modal [(visible)]="isModalOpen" [(busy)]="inProgress">
    <ng-template #abpHeader>
      <h3>{{ '::ReceiverModalHeader' | abpLocalization }}</h3>
    </ng-template>
  
    <ng-template #abpBody>
        <label for="receiver">{{ '::ReceiverModalHeader' | abpLocalization }}</label>
        <div>
          <select (change)="receiverChanged($event)" class="select-box" matNativeControl id="receiver">
            <option disabled selected value> -- {{ '::SelectOption' | abpLocalization }} -- </option>
            <option *ngFor="let option of data" [disabled]="haveAddedBefore(option)" [value]="option.id">{{option.name}} - {{option.email}} - {{option.phone}}</option>
          </select>
        </div>
      
    </ng-template>
  
    <ng-template #abpFooter>
      <button type="button" class="btn btn-secondary" abpClose>
        {{ '::Cancel' | abpLocalization }}
      </button>
  
      <button form="recever-form" class="btn btn-primary" (click)="save()">
        <i class="fa fa-check mr-1"></i>
        {{ '::Save' | abpLocalization }}
      </button>
    </ng-template>
  </abp-modal>