<abp-modal [(visible)]="isModalOpen">
    <ng-template #abpHeader>
      <h3>{{ '::AreYouSureText' | abpLocalization }}</h3>
    </ng-template>
  
    <ng-template #abpBody>
      <div>
        <span style="font-size: medium; font-weight: 500;">{{ '::DeleteTextWithId' | abpLocalization : selectedReceiverId }}</span>
      </div>
    </ng-template>
  
    <ng-template #abpFooter>
      <button type="button" class="btn btn-secondary" abpClose>
        {{ '::Cancel' | abpLocalization }}
      </button>
  
      <button class="btn btn-primary" (click)="delete()">
        <i class="fa fa-check mr-1"></i>
        {{ '::Delete' | abpLocalization }}
      </button>
    </ng-template>
  </abp-modal>