import { Api } from "src/core/decorators/dto/api.decorator";
import { DtoHasApi } from "../shared/dto-has-api.model";
import { ReceiverDto } from "../receiver/receiver.dto";

@Api('api/app/alarm-receivers')
export class AlarmReceiverDto extends DtoHasApi {
    alarmId: number;
    receiverId: number;
    receivername: string;
    receiverDto: ReceiverDto;
}