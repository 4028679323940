
<div class="mat-elevation-z8" style="position: relative;">
    <div class="add-btn-div">
      <h3 style="color: darkslategray; display: inline;">Özel Veriler</h3>
      <button mat-flat-button (click)="openAddModal()" color="accent">{{ '::Add' | abpLocalization }}</button>
    </div>
    <custom-field-add-modal #addModal (onInsertSaved)="getCustomFields()"></custom-field-add-modal>
    <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="dataSource" class="fixed-table">
      <ng-container *ngFor=" let gridColumn of gridColumns">

        <ng-container *ngIf="gridColumn.columnName == 'isActive'" matColumnDef="{{ gridColumn.columnName }}">
          <th [mat-sort-header]="gridColumn.sorterFieldName" mat-header-cell *matHeaderCellDef [style.width]="gridColumn.width" [style.minWidth]="gridColumn.width"
            [style.maxWidth]="gridColumn.width" class="custom-field-header">
            {{ gridColumn.header }}
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-slide-toggle color="green" [checked]="row.isActive" (click)="changeStatus(row)"></mat-slide-toggle>
          </td>
        </ng-container>
  
        <ng-container *ngIf="gridColumn.columnName == 'actions'" matColumnDef="{{ gridColumn.columnName }}">
          <th mat-header-cell *matHeaderCellDef [style.width]="gridColumn.width" [style.minWidth]="gridColumn.width"
            [style.maxWidth]="gridColumn.width" class="custom-field-header">
            {{ gridColumn.header }}
          </th>
          <td class="action-btns" mat-cell *matCellDef="let row">
            <button title="{{ '::Edit' | abpLocalization }}" mat-icon-button color="primary" (click)="openEditModal(row)">
              <mat-icon fontSet="fa" fontIcon="fa-pen fa-xs"></mat-icon>
            </button>
            <button title="{{ '::Delete' | abpLocalization }}" mat-icon-button color="primary" (click)="openDeleteModal(row)">
              <mat-icon fontSet="fa" fontIcon="fa-trash"></mat-icon>
            </button>
          </td>
        </ng-container>
  
        <ng-container
          *ngIf="gridColumn.columnName !== 'isActive' && gridColumn.columnName !== 'actions'"
          matColumnDef="{{ gridColumn.columnName }}">
          <th [mat-sort-header]="gridColumn.sorterFieldName"  mat-header-cell *matHeaderCellDef [style.width]="gridColumn.width" [style.minWidth]="gridColumn.width"
            [style.maxWidth]="gridColumn.width" class="custom-field-header">
            {{ gridColumn.header }}
          </th>
          <td mat-cell *matCellDef="let row" container="body" [innerHtml]="row | objectparser : gridColumn"></td>
        </ng-container>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator 
    (page)="onPageChange($event)"
    [length]="totalCount"
    [pageSize]="pageSize"
    [pageIndex]="currentPage"
    [pageSizeOptions]="[10, 25, 50, 100]" 
    showFirstLastButtons 
    aria-label="Select page">
    </mat-paginator>
    <div *ngIf="isLoading" style="display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.336);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;">
      <mat-progress-spinner color="primary" mode="indeterminate">
      </mat-progress-spinner>
    </div>
  </div>