import { NgModule } from '@angular/core';
import { RecorderRoutingModule } from "./recorder-routing.module";
import { VoiceFileInformationComponent } from './voice-file-information/voice-file-information.component';
import { VoiceFileInformationListComponent } from './voice-file-information/list/voice-file-information-list.component';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { CoreModule } from 'src/core/core.module';
import { CRDatePipe } from 'src/core/pipes/cr-date.pipe';
import { CRTimePipe } from 'src/core/pipes/cr-time.pipe';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { VoiceFileInformationEditModalComponent } from './voice-file-information/edit-modal/voice-file-information-edit-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VoiceFileInformationDeleteModalComponent } from './voice-file-information/delete-modal/voice-file-information-delete-modal.component';
import { MatSortModule } from '@angular/material/sort';
import { LocalizationModule } from '@abp/ng.core';
import { VoiceFileInformationAddModalComponent } from './voice-file-information/add-modal/voice-file-information-add-modal.component';
import { CustomFieldComponent } from './custom-fields/custom-field.component';
import { CustomFieldListComponent } from './custom-fields/list/custom-field-list.component';
import { CustomFieldAddModalComponent } from './custom-fields/add-modal/custom-field-add-modal.component';
import { CustomFieldEditModalComponent } from './custom-fields/edit-modal/custom-field-edit-modal.component';
import { CustomFieldDeleteModalComponent } from './custom-fields/delete-modal/custom-field-delete-modal.component';

@NgModule({
  declarations: [
    VoiceFileInformationComponent,
    VoiceFileInformationListComponent,
    VoiceFileInformationAddModalComponent,
    VoiceFileInformationEditModalComponent,
    VoiceFileInformationDeleteModalComponent,
    CustomFieldComponent,
    CustomFieldListComponent,
    CustomFieldAddModalComponent,
    CustomFieldEditModalComponent,
    CustomFieldDeleteModalComponent
  ],
  imports: [
    CoreModule,
    CommonModule,
    RecorderRoutingModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatButtonModule,
    MatIconModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    ThemeSharedModule,
    LocalizationModule
  ],
  providers: [
    CRDatePipe,
    CRTimePipe
  ]
})

export class RecorderModule {}
