<div>
  <h2> {{ 'Settings::HostRecorder' | abpLocalization }}</h2>
</div>
<br>
<hr>

<form [formGroup]="form" (ngSubmit)="onSubmitForm($event)">
 
  <div class="form-group pt-2" formGroupName="caIdentityUri">
    <label for="caIdentityUri">{{ 'Settings::RecorderCAIdentityUri' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="caIdentityUri" formControlName="value" />
  </div>

  <div class="form-group pt-2" formGroupName="caApiUri">
    <label for="caApiUri">{{ 'Settings::RecorderCAApiUri' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="caApiUri" formControlName="value" />
  </div>

  <hr>
  <h3>{{ 'Settings::RecorderMigrationSettings' | abpLocalization }}</h3>

  <br>
  
  <div class="form-group pt-2" formGroupName="migrationWorkerPeriodSec">
    <label for="migrationWorkerPeriodSec">{{ 'Settings::RecorderMigratorWorkerPeriod' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="migrationWorkerPeriodSec" formControlName="value" />
  </div>

  <div class="form-group pt-2" formGroupName="migrationThreadCount">
    <label for="migrationThreadCount">{{ 'Settings::RecorderMigrationThreadCount' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="migrationThreadCount" formControlName="value" />
  </div>

  <div class="form-group pt-2" formGroupName="migrationProviderLimit">
    <label for="migrationProviderLimit">{{ 'Settings::RecorderMigrationProviderLimit' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="migrationProviderLimit" formControlName="value" />
  </div>

  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3" [disabled]="!form.valid">
    <i class="me-1 fas fa-save"></i>{{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>