import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AdministrationRoutingModule } from './administration-routing.module';
import { SharedModule } from '../shared/shared.module';
import { AngularSplitModule } from 'angular-split';
import { MatTreeModule } from '@angular/material/tree';
import { NgbModalModule, NgbNavModule, NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatSortModule } from '@angular/material/sort';
import { GenesysIntegrationSettingsComponent } from './settings/genesys-integration-settings/genesys-integration-settings.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BlockUIModule } from 'ng-block-ui';
import { IntegrationSettingsComponent } from './settings/integration-settings/integration-settings.component';
import { AmazonIntegrationSettingsComponent } from './settings/amazon-integration-settings/amazon-integration-settings.component';
import { Five9IntegrationSettingsComponent } from './settings/five9-integration-settings/five9-integration-settings.component';
import { RecorderTenantSettingsComponent } from './settings/recorder-settings/tenant/recorder-tenant-settings.component';
import { RecorderHostSettingsComponent } from './settings/recorder-settings/host/recorder-host-settings.component';

@NgModule({
  declarations: [
    GenesysIntegrationSettingsComponent,
    AmazonIntegrationSettingsComponent,
    Five9IntegrationSettingsComponent,
    IntegrationSettingsComponent,
    RecorderTenantSettingsComponent,
    RecorderHostSettingsComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    AngularSplitModule,
    AdministrationRoutingModule,
    MatTooltipModule,
    BlockUIModule,
    MatToolbarModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatTableModule,
    MatCardModule,
    MatListModule,
    MatMenuModule,
    MatButtonModule,
    MatSnackBarModule,
    MatSliderModule,
    MatProgressBarModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatTreeModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    NgbModalModule,
    NgbAccordionModule,
    NgbModule,
    NgbNavModule,
    NgSelectModule,
  ],

})
export class AdministrationModule {}
