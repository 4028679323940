import { Api } from "src/core/decorators/dto/api.decorator";
import { DtoHasApi } from "../shared/dto-has-api.model";

@Api('api/app/audio-formats')
export class AudioFormatDto extends DtoHasApi {
    id: number;
    name: string;
    fileFormatId: number | null;
    isMono: boolean | null;
    sampleRate: number | null;
    bitRate: number | null;
}