<div>
  <h2> {{ 'Settings::TenantRecorder' | abpLocalization }}</h2>
</div>
<br>
<hr>

<form [formGroup]="form" (ngSubmit)="onSubmitForm($event)">
  <div class="form-group pt-2" formGroupName="continueRecordingOnHold">
    <input type="checkbox" id="continueRecordingOnHold" formControlName="value" />
    <label for="continueRecordingOnHold">{{ 'Settings::RecorderContinueRecordingOnHold' | abpLocalization }}</label>
  </div>

  <div class="form-group pt-2" formGroupName="addSilenceForHold">
    <input type="checkbox" id="addSilenceForHold" formControlName="value" />
    <label for="addSilenceForHold">{{ 'Settings::RecorderAddSilenceForHold' | abpLocalization }}</label>
  </div>

  <hr>
  <h3>{{ 'Settings::RecorderMigrationSettings' | abpLocalization }}</h3>
  
  <div class="form-group pt-2" formGroupName="deleteMigratedAudioFile">
    <input type="checkbox" id="deleteMigratedAudioFile" formControlName="value" />
    <label for="deleteMigratedAudioFile">{{ 'Settings::RecorderDeleteMigratedAudioFile' | abpLocalization }}</label>
  </div>

  <br>
  
  <div class="form-group pt-2" formGroupName="caUsername">
    <label for="caUsername">{{ 'Settings::RecorderCAUsername' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="caUsername" formControlName="value" />
  </div>

  <div class="form-group pt-2" formGroupName="caKeyword">
    <label for="caKeyword">{{ 'Settings::RecorderCAKeyword' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="caKeyword" formControlName="value" />
  </div>

  <div class="form-group pt-2" formGroupName="caSourceName">
    <label for="caSourceName">{{ 'Settings::RecorderCASourceName' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="caSourceName" formControlName="value" />
  </div>

  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3" [disabled]="!form.valid">
    <i class="me-1 fas fa-save"></i>{{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>