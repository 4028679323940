import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { permissionGuard } from '@abp/ng.core';
import { ReceiversComponent } from './receivers/receivers.component';
import { AlarmListComponent } from './alarm-receivers/alarm-list/alarm-list.component';

const routes: Routes = [
  {
    canActivate: [permissionGuard],
    path: 'receivers',
    component: ReceiversComponent,
  },
  {
    canActivate: [permissionGuard],
    path: 'alarm-receivers',
    component: AlarmListComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AlarmRoutingModule { }
