<ng-container *ngIf="(service.currentTenant$ | async) || { name: '' } as currentTenant">
  <div class="card shadow-sm rounded mb-3">
    <div class="card-body px-5">
      <div class="row">
        <div class="col">
          <span style="font-size: 0.8em" class="text-uppercase text-muted">{{
            'AbpUiMultiTenancy::Tenant' | abpLocalization
          }}</span
          ><br />
          <h6 class="m-0 d-inline-block">
            <i>{{ currentTenant.name || ('AbpUiMultiTenancy::NotSelected' | abpLocalization) }}</i>
          </h6>
        </div>
        <div class="col-auto">
          <a
            id="AbpTenantSwitchLink"
            href="javascript:void(0);"
            class="btn btn-sm mt-3 btn-outline-primary"
            (click)="service.onSwitch()"
            >{{ 'AbpUiMultiTenancy::Switch' | abpLocalization }}</a
          >
        </div>
      </div>
    </div>
  </div>

  <abp-modal [(visible)]="service.isModalVisible" [busy]="service.modalBusy">
    <ng-template #abpHeader>
      <h5>Switch Tenant</h5>
    </ng-template>
    <ng-template #abpBody>
      <form (ngSubmit)="service.save()">
        <div class="mt-2">
          <div class="mb-3 form-group">
            <label for="name" class="form-label">{{
              'AbpUiMultiTenancy::Name' | abpLocalization
            }}</label>
            <input
              [(ngModel)]="service.name"
              type="text"
              id="name"
              name="tenant"
              class="form-control"
              autofocus
            />
          </div>
          <p>{{ 'AbpUiMultiTenancy::SwitchTenantHint' | abpLocalization }}</p>
        </div>
      </form>
    </ng-template>
    <ng-template #abpFooter>
      <button abpClose type="button" class="btn btn-outline-primary">
        {{ 'AbpTenantManagement::Cancel' | abpLocalization }}
      </button>
      <abp-button
        type="abp-button"
        iconClass="fa fa-check"
        (click)="service.save()"
        [disabled]="currentTenant?.name === service.name"
      >
        <span>{{ 'AbpTenantManagement::Save' | abpLocalization }}</span>
      </abp-button>
    </ng-template>
  </abp-modal>
</ng-container>
