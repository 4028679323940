import { Api } from "src/core/decorators/dto/api.decorator";
import { DtoHasApi } from "../shared/dto-has-api.model";

@Api('api/app/receivers')
export class ReceiverDto extends DtoHasApi {
    id: number;
    name: string;
    email: string;
    phone: string;
    isActive: boolean;
}