<abp-modal [(visible)]="isModalOpen" [(busy)]="inProgress">
  <ng-template #abpHeader>
    <h3>{{ '::ReceiverModalHeader' | abpLocalization }}</h3>
  </ng-template>

  <ng-template #abpBody>
    <form id="receiver-form" [formGroup]="form">
      <div class="form-group">
        <label for="name">{{ '::Name' | abpLocalization }}</label>
        <input type="text" id="name" class="form-control" formControlName="name" />
      </div>

      <div class="form-group">
        <label for="email">{{ '::Email' | abpLocalization }}</label>
        <input type="text" id="email" class="form-control" formControlName="email" />
      </div>

      <div class="form-group">
        <label for="phone">{{ '::Phone' | abpLocalization }}</label>
        <input type="text" id="phone" class="form-control" formControlName="phone" />
      </div>
    </form>
  </ng-template>

  <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ '::Cancel' | abpLocalization }}
    </button>

    <button form="recever-form" class="btn btn-primary" [disabled]="form.invalid || form.pristine" (click)="save()">
      <i class="fa fa-check mr-1"></i>
      {{ '::Save' | abpLocalization }}
    </button>
  </ng-template>
</abp-modal>