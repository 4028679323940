import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApplicationLayoutComponent } from '@volo/abp.ng.theme.lepton';
// import { AttachedDataMainComponent } from './attached-data/attached-data-main/attached-data-main.component';
// import { DepartmentMainComponent } from './department/department-main/department-main.component';
// import { GenericLookupTypeMainComponent } from './generic-lookup/generic-lookup-type-main/generic-lookup-type-main.component';
// import { SourceMainComponent } from './source/source-main/source-main.component';
// import { IntegrationMainComponent } from './integration/integration-main/integration-main.component';
// import { IgnoredWordMainComponent } from './ignored-word/ignored-word-main/ignored-word-main.component';
// import { DeviceGroupMainComponent } from './call-recorder/device-group/device-group-main/device-group-main.component';
// import { DeviceMainComponent } from './call-recorder/device/device-main/device-main.component';
// import { InteractionManagementMainComponent } from '../user-group/interaction-management/interaction-management-main/interaction-management-main.component';
// import { ProfileMainComponent } from './screen-recorder-management/profile/profile-main/profile-main.component';
// import { ReasonMainComponent } from './reason/reason-main/reason-main.component';
// import { TagMainComponent } from './tag/tag-main/tag-main.component';
// import { UserLicenseMainComponent } from './licensing/user-license/user-license-main/user-license-main.component';
// import { AuthGuard, PermissionGuard } from '@abp/ng.core';
// import { EmailReportLogMainComponent } from '../report/email-report-log/email-report-log-main/email-report-log-main.component';
// import { SettingGuard } from 'src/core/guards/setting.guard';

const routes: Routes = [
  {
    path: 'integration',
    component: ApplicationLayoutComponent,
    data: { title: '::IntegrationService' },
    children: [
      // {
      //   path: '',
      //   component: IntegrationMainComponent,
      // },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdministrationRoutingModule { }
