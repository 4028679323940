import { LocalizationService } from '@abp/ng.core';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ReceiverDto } from 'src/core/models/receiver/receiver.dto';
import { CrudService } from 'src/core/services/crud/crud.service';

@Component({
  selector: 'receiver-edit-modal',
  templateUrl: './receiver-edit-modal.component.html',
  styleUrl: './receiver-edit-modal.component.scss',
})
export class ReceiverEditModalComponent {
  @Output()
  onUpdateSaved: EventEmitter<{}> = new EventEmitter();

  form = this.fb.group({
    name: ['', [Validators.required]],
    email: ['', [Validators.required]],
    phone: ['', [Validators.required]],
  });

  selectedReceiverDto: ReceiverDto = null;
  inProgress: boolean;
  isModalOpen: boolean;

  constructor(private fb: FormBuilder, private crudService: CrudService, private L: LocalizationService) {
  }
  save() {
    if (this.form.invalid) {
      return;
    }
    this.inProgress = true;

    this.selectedReceiverDto.name = this.form.controls["name"].value;
    this.selectedReceiverDto.email = this.form.controls["email"].value;
    this.selectedReceiverDto.phone = this.form.controls["phone"].value;

    this.crudService
      .update<ReceiverDto>(ReceiverDto, this.selectedReceiverDto)
      .subscribe((result) => {
        if (result) {
          this.onUpdateSaved.emit();
        }
        this.isModalOpen = false;
        this.inProgress = false;
        this.form.reset();
      });
  }

  load(data: ReceiverDto | null) {
    if (data == null) {
      data = new ReceiverDto();
    } else {
      this.selectedReceiverDto = data;
      this.form.patchValue(data);
    }
  }
}