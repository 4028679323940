import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DtoHasApi } from 'src/core/models/shared/dto-has-api.model';
import { environment } from 'src/environments/environment';
import { ListResponseDto } from 'src/core/models/request/list-response.dto';
import { AudioFormatDto } from 'src/core/models/audio-format/audio-format-dto';

@Injectable({
    providedIn: 'root',
})
export class AudioFormatService {
    constructor(private http: HttpClient) { }

    public getAudioFormats(): Observable<ListResponseDto<AudioFormatDto>> {
        const apiUrl = `${this.getApiUrl(AudioFormatDto)}`;
        return this.http.get(`${apiUrl}?SkipCount=0&MaxResultCount=500`) as Observable<ListResponseDto<AudioFormatDto>>;
    }

    protected getApiUrl(type = DtoHasApi): string {
        const apiBase = environment.apis.default.url;
        return apiBase + '/' + type.apiUrl;
    }

}