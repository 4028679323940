import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';

@Component({
  selector: 'cr-recorder-host-settings',
  templateUrl: './recorder-host-settings.component.html',
  styleUrls: ['./recorder-host-settings.component.scss'],
})
export class RecorderHostSettingsComponent implements OnInit {
  form: FormGroup;

  private readonly prefix = 'Recorder';
  private readonly caIdentityUri = `${this.prefix}.CAIdentityUri`;
  private readonly caApiUri = `${this.prefix}.CAApiUri`;
  private readonly migrationWorkerPeriodSec = `${this.prefix}.MigrationWorkerPeriodSec`;
  private readonly migrationThreadCount = `${this.prefix}.MigrationThreadCount`;
  private readonly migrationProviderLimit = `${this.prefix}.MigrationProviderLimit`;


  constructor(
    private localizationService: LocalizationService,
    private fb: FormBuilder,
    private config: ConfigStateService,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService) {

    this.form = this.fb.group({
      caIdentityUri: this.fb.group({
        name: this.caIdentityUri,
        value: [
          this.config.getSetting(this.caIdentityUri),
        ],
      }),
      caApiUri: this.fb.group({
        name: this.caApiUri,
        value: [
          this.config.getSetting(this.caApiUri),
        ],
      }),
      migrationWorkerPeriodSec: this.fb.group({
        name: this.migrationWorkerPeriodSec,
        value: [
          this.config.getSetting(this.migrationWorkerPeriodSec),
        ],
      }),
      migrationThreadCount: this.fb.group({
        name: this.migrationThreadCount,
        value: [
          this.config.getSetting(this.migrationThreadCount),
        ],
      }),
      migrationProviderLimit: this.fb.group({
        name: this.migrationProviderLimit,
        value: [
          this.config.getSetting(this.migrationProviderLimit),
        ],
      })
    })
  }


  onSubmitForm(eventArgs): void {
    if (!this.form.valid) {
      return;
    }

    const settings: ConfigurationSettingsDto[] = [];

    settings.push({
      settingName: this.form.get('caIdentityUri').get('name').value,
      settingValue: this.form.get('caIdentityUri').get('value').value,
    });

    settings.push({
      settingName: this.form.get('caApiUri').get('name').value,
      settingValue: this.form.get('caApiUri').get('value').value,
    });

    settings.push({
      settingName: this.form.get('migrationWorkerPeriodSec').get('name').value,
      settingValue: this.form.get('migrationWorkerPeriodSec').get('value').value,
    });

    settings.push({
      settingName: this.form.get('migrationThreadCount').get('name').value,
      settingValue: this.form.get('migrationThreadCount').get('value').value,
    });

    settings.push({
      settingName: this.form.get('migrationProviderLimit').get('name').value,
      settingValue: this.form.get('migrationProviderLimit').get('value').value,
    });

    this.settingService.saveSetting(settings).subscribe(res => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
      );
    });
  }

  ngOnInit(): void { }
}
