import { LocalizationService } from '@abp/ng.core';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomFieldDto } from 'src/core/models/custom-field/custom-field.dto';
import { CrudService } from 'src/core/services/crud/crud.service';

@Component({
  selector: 'custom-field-add-modal',
  templateUrl: './custom-field-add-modal.component.html',
  styleUrl: './custom-field-add-modal.component.scss',
})
export class CustomFieldAddModalComponent {
  @Output()
  onInsertSaved: EventEmitter<{}> = new EventEmitter();

  form = this.fb.group({
    fieldKey: ['', [Validators.required]],
    pbxKey: ['', [Validators.required]],
  });

  newCustomField: CustomFieldDto = new CustomFieldDto();
  inProgress: boolean;
  isModalOpen: boolean;

  constructor(private fb: FormBuilder, private crudService: CrudService, private L: LocalizationService) {
  }


  save() {
    if (this.form.invalid) {
      return;
    }
    this.inProgress = true;
    this.newCustomField.fieldKey = this.form.controls["fieldKey"].value;
    this.newCustomField.pbxKey = this.form.controls["pbxKey"].value;

    this.crudService
      .insertWithoutId<CustomFieldDto>(CustomFieldDto, this.newCustomField)
      .subscribe((result) => {
        if (result) {
          this.onInsertSaved.emit();
        }
        this.isModalOpen = false;
        this.inProgress = false;
        this.form.reset();
      });
  }

  load(data: CustomFieldDto | null) {
    if (data == null) {
      data = new CustomFieldDto();
    } else {
      this.newCustomField = data;
      this.form.patchValue(data);
    }
  }

}