import { NgModule } from '@angular/core';
import { Routes, RouterModule, mapToCanActivate } from '@angular/router';
import { VoiceFileInformationComponent } from './voice-file-information/voice-file-information.component';
import { permissionGuard } from '@abp/ng.core';
import { CustomFieldComponent } from './custom-fields/custom-field.component';

const routes: Routes = [
    {
      canActivate: [permissionGuard],
      path: 'voice-file',
      component: VoiceFileInformationComponent,
    },
    // {
    //   canActivate: [permissionGuard],
    //   path: 'settings',
    //   component: RecorderSettingsComponent,
    // },
    {
      canActivate: [permissionGuard],
      path: 'custom-fields',
      component: CustomFieldComponent,
    },
  ];
  
  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
  })
  export class RecorderRoutingModule {}
  