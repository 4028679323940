import { LocalizationService } from '@abp/ng.core';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AlarmReceiverDto } from 'src/core/models/alarm-receiver/alarm-receiver.dto';
import { ReceiverDto } from 'src/core/models/receiver/receiver.dto';
import { CrudService } from 'src/core/services/crud/crud.service';

@Component({
    selector: 'receiver-selectable-add-modal',
    templateUrl: './selectable-add-modal.component.html',
    styleUrl: './selectable-add-modal.component.scss',
})
export class ReceiverSelectableAddModalComponent {
    @Output()
    onInsertSaved: EventEmitter<{}> = new EventEmitter();

    selectedAlarm: any;
    data: ReceiverDto[] = [];
    dataSource = new MatTableDataSource<ReceiverDto>();
    displayedColumns: string[] = [];
    isLoading: boolean = false;
    totalCount = 0;
    newReceiver: AlarmReceiverDto = new AlarmReceiverDto();
    inProgress: boolean;
    isModalOpen: boolean;
    alarmReceiversList: any[];

    constructor(private fb: FormBuilder, private crudService: CrudService, private L: LocalizationService) {
    }

    receiverChanged(event){
        const target = event.target;
        const selectedOption = target.options[target.selectedIndex];
        var items = selectedOption.text.split(" - ")

        this.newReceiver.alarmId = this.selectedAlarm.id;
        this.newReceiver.receiverId = parseInt(selectedOption.value);
        this.newReceiver.receivername = items[0]
    }

    haveAddedBefore(option){
        if(this.alarmReceiversList.findIndex(receiver => receiver.receiverId == option.id) != -1){
            return true;
        }
    }

    save() {
        this.inProgress = true;
        this.crudService
            .insertWithoutId<AlarmReceiverDto>(AlarmReceiverDto, this.newReceiver)
            .subscribe((result) => {
                if (result) {
                    this.onInsertSaved.emit();
                }
                this.isModalOpen = false;
                this.inProgress = false;

                const addedReceiver = this.data.find(item => item.id == this.newReceiver.receiverId);

            });
    }

    ngOnInit() {
        this.crudService
            .abpGet<ReceiverDto>(
                ReceiverDto,
                {
                    filters: [],
                    sorters: [],
                    skipCount: 0,
                    maxResultCount: 500,
                },
                '', '', null)
            .subscribe(result => {
                this.isLoading = false;
                this.data = result.items;
                this.totalCount = result.totalCount;
                this.dataSource.connect().next(this.data);
                this.onInsertSaved.emit();
            });
    }

}