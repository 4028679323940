import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { CoreModule } from 'src/core/core.module';
import { CRDatePipe } from 'src/core/pipes/cr-date.pipe';
import { CRTimePipe } from 'src/core/pipes/cr-time.pipe';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { LocalizationModule } from '@abp/ng.core';
import { AlarmRoutingModule } from './alarm-routing.module';
import { ReceiversComponent } from './receivers/receivers.component';
import { ReceiversListComponent } from './receivers/list/receivers-list.component';
import { ReceiverEditModalComponent } from './receivers/edit-modal/receiver-edit-modal.component';
import { ReceiverDeleteModalComponent } from './receivers/delete-modal/receiver-delete-modal.component';
import { ReceiverAddModalComponent } from './receivers/add-modal/receiver-add-modal.component';
import { AlarmReceiversComponent } from './alarm-receivers/alarm-receivers.component';
import { AlarmReceiversListModalComponent } from './alarm-receivers/alarm-receiver-list-modal/alarm-receivers-list-modal.component';
import { AlarmListComponent } from './alarm-receivers/alarm-list/alarm-list.component';
import { ReceiverSelectableAddModalComponent } from './alarm-receivers/selectable-add-modal/selectable-add-modal.component';

@NgModule({
  declarations: [
    ReceiversComponent,
    ReceiversListComponent,
    ReceiverEditModalComponent,
    ReceiverDeleteModalComponent,
    ReceiverAddModalComponent,
    ReceiverSelectableAddModalComponent,
    AlarmListComponent,
    AlarmReceiversComponent,
    AlarmReceiversListModalComponent
  ],
  imports: [
    CoreModule,
    CommonModule,
    AlarmRoutingModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatButtonModule,
    MatIconModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    ThemeSharedModule,
    LocalizationModule
  ],
  providers: [
    CRDatePipe,
    CRTimePipe
  ]
})

export class AlarmModule { }
