import { Component, OnInit, ViewChild } from '@angular/core';
import { ReceiverEditModalComponent } from './edit-modal/receiver-edit-modal.component';
import { ReceiversListComponent } from './list/receivers-list.component';
import { ReceiverDeleteModalComponent } from './delete-modal/receiver-delete-modal.component';

@Component({
  selector: 'cr-receivers',
  templateUrl: './receivers.component.html',
  styleUrls: ['./receivers.component.scss'],
})

export class ReceiversComponent implements OnInit {
  @ViewChild('editModal', { static: true, read: ReceiverEditModalComponent })
  editModal: ReceiverEditModalComponent;

  @ViewChild('deleteModal', { static: true, read: ReceiverDeleteModalComponent })
  deleteModal: ReceiverDeleteModalComponent;

  @ViewChild('list', { static: true, read: ReceiversListComponent })
  list: ReceiversListComponent;

  constructor() { }

  ngOnInit(): void {}

  getAllReceivers(event){
    this.list.getAllReceivers();
  }

  openEditModal(data: any) {
    this.editModal.load(data.data);
    this.editModal.isModalOpen = true;
  }

  openDeleteModal(id: number){
    this.deleteModal.selectedReceiverId = id;
    this.deleteModal.isModalOpen = true;
  }
}