import { ConfigStateService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class GlobalSettingsService {
  constructor(private config: ConfigStateService, private http: HttpClient) { }

  public get systemTimeZone(): string {
    return this.config.getSetting('Global.SystemTimeZone');
  }

  public get systemDateFormat(): string {
    return this.config.getSetting('Global.SystemDateFormat');
  }

}
