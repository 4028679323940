import { LocalizationService } from '@abp/ng.core';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomFieldDto } from 'src/core/models/custom-field/custom-field.dto';
import { CrudService } from 'src/core/services/crud/crud.service';

@Component({
  selector: 'custom-field-edit-modal',
  templateUrl: './custom-field-edit-modal.component.html',
  styleUrl: './custom-field-edit-modal.component.scss',
})
export class CustomFieldEditModalComponent {
  @Output()
  onUpdateSaved: EventEmitter<{}> = new EventEmitter();

  form = this.fb.group({
    fieldKey: ['', [Validators.required]],
    pbxKey: ['', [Validators.required]],
  });

  selectedCf: CustomFieldDto = null;
  inProgress: boolean;
  isModalOpen: boolean;

  constructor(private fb: FormBuilder, private crudService: CrudService, private L: LocalizationService) {
  }
  save() {
    if (this.form.invalid) {
      return;
    }
    this.inProgress = true;

    this.selectedCf.fieldKey = this.form.controls["fieldKey"].value;
    this.selectedCf.pbxKey = this.form.controls["pbxKey"].value;

    this.crudService
      .update<CustomFieldDto>(CustomFieldDto, this.selectedCf)
      .subscribe((result) => {
        if (result) {
          this.onUpdateSaved.emit();
        }
        this.isModalOpen = false;
        this.inProgress = false;
        this.form.reset();
      });
  }

  load(data: CustomFieldDto | null) {
    if (data == null) {
      data = new CustomFieldDto();
    } else {
      this.selectedCf = data;
      this.form.patchValue(data);
    }
  }
}